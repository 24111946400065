/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable */
/* global params */
/* global FWP */

import Scroll from '../util/Scroll';
import barba from '@barba/core';
import anime from 'animejs/lib/anime.es.js';
import yearsAchive from '../routes/years-archive';
// import facetLoader from '../routes/facet-loader';
import dotsMenu from '../routes/dots-menu.js';
import archiveFilter from '../routes/archive-filter';
import Carousel from '../util/Carousel';
import VideoPlayer from '../util/VideoPlayer';
import AudioPlayer from '../util/AudioPlayer';


class Navigation {

  constructor(target) {
    
    this.main = target;  
    this.easing = 'cubicBezier(0.2, 0.31, 0.16, 0.69)';
    this.scroll = null;
    this.hamburger = document.querySelector('.hamburger');
    this.navigationContainer = document.getElementById('navigation__container');
    this.navigationMask = document.getElementById('mask--navigation__svg');

  }

  init() {

    this.scroll = new Scroll(document.querySelector('[data-scroll-container]'));
    this.scroll.init();
    
    this.initBarba();

    this.handleEvents();
    
  }

  initBarba() {

    barba.init({
      sync: true,
      cacheIgnore: false,
      debug: false,
      timeout: 5000,
      transitions: [
        { 
          name: 'archive-to-archive',
          from: {
            namespace: ['archive'],
          },
          to: {
            namespace: ['archive'],
          },

          leave: ({current, next}) => {

            return new Promise(resolve => {
              resolve();
              
              // const sidebar = document.getElementById('sidebar__container');
              // const currentLine = sidebar.querySelector('.current > .line');
              // const currentLink = sidebar.querySelector('.current > span');
              
              // const nextYear = next.container.querySelector('.sidebar--years .current').dataset.termId;
              // const nextLine = sidebar.querySelector(`[data-term-id="${nextYear}"] > .line`);
              // const nextLink = sidebar.querySelector(`[data-term-id="${nextYear}"] > a`);
              
              // const tl = anime.timeline({
              //   duration: 600,
              //   easing: 'easeOutExpo',
              //   complete: () => {
              //     resolve();
              //   }
              // });

              // tl.add({
              //   targets: currentLine,
              //   width: 0,
              // }).add({
              //   targets: nextLink,
              //   opacity: 1,
              // }).add({
              //   targets: currentLink,
              //   opacity: 0.2,
              // }).add({
              //   targets: nextLine,
              //   width: '100%',
              // }, '-=200');
            });  
          }, 

          enter: ({current, next}) => {
            return new Promise(resolve => {

              this.closeNavigation();
              
              // const targetCurrent = current.container.querySelectorAll('.single--award');
              // const targetNext = next.container.querySelectorAll('.single--award');

              const tl = anime.timeline({
                duration: 800,
                easing: 'easeOutExpo',
                complete: () => {
                  resolve();
                }
              });

              tl.add({
                targets: current.container,
                marginTop: `-${current.container.offsetHeight}px`,
              });
            });
          },

          afterEnter: ( { current, next } ) =>  this.yearsArchive(current, next),

          after: ( { next } ) =>  this.afterPage(next)
        },
        { 
          name: 'page-to-archive',
          from: {
            namespace: ['page'],
          },
          to: {
            namespace: ['archive'],
          },

          leave: async ({current, next}) => {

            return new Promise(resolve => {
              resolve();

              // const sidebar = document.getElementById('#sidebar__container');
              // const tl = anime.timeline({
              //   duration: 600,
              //   easing: 'easeOutExpo',
              //   complete: () => {
              //     resolve();
              //   }
              // });

              // tl.add({
              //   targets: sidebar,
              //   width: '280px',
              // });
            });
          },

          enter: ({current, next}) => {
            return new Promise(resolve => {

              this.closeNavigation();
              resolve();

            });
          },
        
          afterEnter: ( { current, next } ) =>  this.yearsArchive(current, next),

          after: ( { next } ) => this.afterPage(next)

        },
        { 
          name: 'archive-to-page',
          from: {
            namespace: ['archive'],
          },
          to: {
            namespace: ['page'],
          },

          enter: ({current, next}) => {

            return new Promise(resolve => {
              this.closeNavigation();
              resolve();
            });
          },

          afterEnter: ( {current, next } ) => this.enterPage(current, next),
          
          after: ( { next } ) =>  {

            if (document.querySelectorAll('[data-barba-nametype]')) {
              if (document.querySelector("[data-barba-nametype='Ricordi Speciali']")) {
                this.enterMemories(next);
              }
              if (document.querySelector("[data-barba-nametype='Archivio']")) {
                this.enterArchive(next);
              }
            }

            this.afterPage(next);
          }
        },
        { 
          name: 'page-to-page',
          from: {
            namespace: ['page'],
          },
          to: {
            namespace: ['page'],
          },

          enter: ({current, next}) => {

            return new Promise(resolve => {
              this.closeNavigation();
              resolve();
            });
          },

          afterEnter: ( {current, next } ) => this.enterPage(current, next),

          after: ( { next } ) =>  {

            if (document.querySelectorAll('[data-barba-nametype]')) {
              if (document.querySelector("[data-barba-nametype='Ricordi Speciali']")) {
                this.enterMemories(next);
              }
              if (document.querySelector("[data-barba-nametype='Archivio']")) {
                this.enterArchive(next);
              }
            }

            this.afterPage(next);
          }
        },
      ],
    });

  }

  next() {
    let nextYear;

    nextYear = document.getElementById('sidebar__container').querySelector('.current').nextElementSibling;
    
    if(!nextYear) {
      nextYear = document.getElementById('sidebar__container').querySelectorAll('li')[0];
    }

    if(nextYear){
      barba.go(nextYear.querySelector('a').href);
    }
  }

  handleEvents() {

     this.scroll.smooth.on('call', func => {
       this[func]();
     });

    this.hamburger.addEventListener('click', e => {

      if(!e.currentTarget.classList.contains('is-active')){
        this.openNavigation();
      } else {
        this.closeNavigation();
      }
    });

    this.hamburger.addEventListener('mouseover', ()=>{
      this.navigationContainer.classList.add('hover');
    }, false);

    this.hamburger.addEventListener('mouseout', ()=>{
      this.navigationContainer.classList.remove('hover');
    }, false);
    
    if(document.querySelector('#filter_opener')) {
      
      document.getElementById('filter_opener').addEventListener('click', () => {
        archiveFilter.filterMenuOpen();
      });
  
      document.getElementById('filter_closer').addEventListener('click', () => {
        console.log('closer click!');
        archiveFilter.filterMenuClose();
      });
    }
  }

  openNavigation() {

    const maskLeft = 48;
    const maskTop = 45;
    const maskScale = 100;

    document.body.classList.add('navigation-open');

    anime.remove( this.navigationMask );

    anime({
      targets: this.navigationMask,
      translateX: (1 - maskScale) * maskLeft,
      translateY: (1 - maskScale) * maskTop,
      scale: maskScale,
      duration: 1200,
      easing: this.easing,
    });

    this.hamburger.classList.add('is-active');
  }

  closeNavigation() {

    anime.remove( this.navigationMask );

    anime({
      targets: this.navigationMask,
      translateX: 0,
      translateY: 0,
      scale: 0,
      duration: 600,
      easing: this.easing,
      complete: (anim) => {
        
        document.body.classList.remove('navigation-open');
        this.hamburger.classList.remove('is-active');
      },
    });
  }

  async enterPage( current, next ){
    // alert('enterPage');
    // console.log('enterPage');

    this.updatePageElements(next.html);
    yearsAchive.destroy();

    console.log(next);
  }

  async enterArchive( next ){
    // facetLoader.init();

    console.log('enterArchive');

    archiveFilter.init();

    this.updatePageElements(next.html);

    this.pageFacet();
  }

  async yearsArchive( current, next ){
    this.updatePageElements(next.html);
    yearsAchive.init(next.html);
  }

  async enterMemories(next){
    this.updatePageElements(next.html);

    if (next) {
      const parser = new DOMParser();
      const html = parser.parseFromString(next.html, 'text/html');

      if(document.querySelectorAll('.dotmenu')){
        const dotmenu_dots = html.body.querySelectorAll('.dotmenu .dot');

        if(dotmenu_dots) {
          dotsMenu.removeDotCurrent(next.url.href , dotmenu_dots);
        }

      }
    }
    
  }

  async afterPage(next){
    this.scroll.destroy();
    this.scroll = new Scroll(document.querySelector('[data-scroll-container]'));
    this.scroll.init();

    this.scroll.smooth.on('call', func => {
      this[func]();
    });

    this.pageFacet();

    // Swiper Carousel
    const carousels = [];
    document.querySelectorAll('.carousel__container').forEach( element => {
      carousels.push(new Carousel(element));
    });

    // Viewer Video
    const videos = [];
    document.querySelectorAll('.video_player_start').forEach( element => {
       videos.push(new VideoPlayer(element));
    });

    // Listen Audio
    const audios = [];
    document.querySelectorAll('.btn-audio').forEach( element => {
      audios.push(new AudioPlayer(element));
    });
  }
  
  updatePageElements(html) {
    let currentItem, ariaCurrent;

    const parser = new DOMParser();
    const contentPage = parser.parseFromString(html, 'text/html');
    
    contentPage.body.classList.add("navigation-open");
    
    if(document.querySelector("[aria-current='page']")){
      ariaCurrent = document.querySelector('[aria-current="page"]');
    }

    if(ariaCurrent) {
      ariaCurrent.removeAttribute('aria-current');
    }
    
    document.documentElement.lang = contentPage.documentElement.lang;
    document.body.classList = contentPage.body.classList;

  }

  pageFacet() {
    // Hello FacetWP
    if (!FWP.loaded) {
      if (!FWP.settings.years) {
        FWP.settings.years = { 'show_expanded': 'no' };
      }
      if (!FWP.settings.places) {
        FWP.settings.places = { 'show_expanded': 'no' };
      }
    }

    FWP.init();
  }
}

export default Navigation;