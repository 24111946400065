/* eslint-disable no-unused-vars */
/* global params */

import anime from 'animejs/lib/anime.es.js';

export default {
  
  init(html) {
    
    const parser = new DOMParser();

    if(html){
      const content = parser.parseFromString(html, 'text/html');
      this.initSidebar(content.querySelector('.sidebar--years').querySelector('ul'));
    } else {
      this.initSidebar(document.querySelector('.sidebar--years').querySelector('ul'));
    }
   
    this.handleEvents();
  },  

  finalize() {
    
  },

  handleEvents() {
    const sidebarLinks = document.querySelectorAll('#sidebar__container > ul > li:not(.current)');

    sidebarLinks.forEach( ( element ) => {
      element.addEventListener('mouseover', (e) => {
        
       this.stressYear(e.currentTarget);
       this.deStressYear(document.querySelector('#sidebar__container > ul > .current'));

      });

      element.addEventListener('mouseout', (e) => {
        
        this.deStressYear(e.currentTarget);
        this.stressYear(document.querySelector('#sidebar__container > ul > .current'));

      });

      

    });
  },

  stressYear(element) {
    const tl = anime.timeline({
      duration: 600,
      easing: 'easeOutExpo',
    });

    tl.add({
      targets: element.querySelector('.line'),
      width: '100%',
    }).add({
      targets: element.querySelector('.year'),
      opacity: 1,
    }, '-=200');
  },

  deStressYear(element) {
    const tl = anime.timeline({
      duration: 600,
      easing: 'easeOutExpo',
    });

    tl.add({
      targets: element.querySelector('.line'),
      width: 0,
    }).add({
      targets: element.querySelector('.year'),
      opacity: 0.2,
    }, '-=200');
  },

  destroy() {
    this.destroySidebar();
   },  

  initSidebar(sidebarContent) {
    console.log('initSidebar');
    const sidebar = document.getElementById('sidebar__container');
    const menu = sidebar.querySelector('ul');
    if(menu){
      sidebar.removeChild(sidebar.querySelector('ul'));
    }
   
    sidebar.appendChild(sidebarContent);
    document.body.classList.add('sidebar-active');
  },

  destroySidebar() {
    const sidebar = document.getElementById('sidebar__container');
    const menu = sidebar.querySelector('ul');
    if(menu){
      sidebar.removeChild(sidebar.querySelector('ul'));
    }
    document.body.classList.remove('sidebar-active');
  },

};
