/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* global params */

import YouTubePlayer from 'youtube-player';
import anime from 'animejs/lib/anime.es.js';
import { bezier, getVideoId } from '../util/Utils';


class VideoPlayer {

  constructor(target) {
    
    this.target = target;  
    
    this.youTubeOptions = {
      autoplay: 1,
      controls: 1,
      iv_load_policy: 3,
      cc_load_policy: 1,
      loop: 0,
      modestbranding: 1,
      rel: 0,
      playsinline: 1,
      color: 'white',
      // showinfo: 0, // Deprecated // Visualizza barra superiore : "Youtuber Name" , "Watch Later" e "Share"
    };

    this.videoId = '';
    this.playerContainer = null;

    this.init();
  }

  init() {
    
    this.videoId = getVideoId(this.target.dataset.videoId);
    this.handleEvents();

  } 

  handleEvents() {

    // this.target.addEventListener('mouseenter', (e) =>{
      
    //   this.animateButton(e.currentTarget.querySelector('.play-button'), 'normal');
    // });

    // this.target.addEventListener('mouseleave', (e) =>{
      
    //   this.animateButton(e.currentTarget.querySelector('.play-button'), 'normal');
    // });
    
    this.target.addEventListener('click', () => {
      this.playVideo();
    });

  }

  // animateButton(target, direction) {

  //   const timeline = anime.timeline({  
  //     autoplay: true,
  //     easing: bezier,
  //     duration: 1500, 
  //     direction: direction,
  //     loop: false,
  //   });

  //   timeline
  //   .add({
  //     targets: target.querySelector('.circle'),
  //     strokeDashoffset: [anime.setDashoffset, 0],
  //   }, 0)
  //   .add({
  //     targets: target.querySelector('.play'),
  //     scale: [1, 0.7],
  //   }, 0);
  // }

  playVideo() {
    this.playerContainer = document.createElement('div');
    this.playerContainer.classList.add('video__player--container');

    const player = document.createElement('div');
    player.classList.add('video__player');
    this.playerContainer.appendChild(player);

    const player_close = document.createElement('div');
    player_close.classList.add('video__closer');
    this.playerContainer.appendChild(player_close);

    document.body.appendChild(this.playerContainer);
   
    // Not in HandleEvent because have error - element not found !
    document.querySelector('.video__closer').addEventListener('click', () => {
      this.removeVideo();
    });

    this.player = this.setPlayerYouTube(player);

    this.player.on('stateChange', (event) => {
      switch(event.data){
        case 0:
          this.removeVideo();
          break;
      }
    });

    const timeline = anime.timeline({  
      autoplay: false,
      easing: bezier,
      duration: 2200, 
    });

    timeline
    .add({
      targets: document.querySelector('.wrap'),
      translateX: '100%',
      begin: () => {
        this.player.playVideo();
      },
    }, 0)
    .add({
      targets: this.playerContainer,
      translateX: ['-100%', '0'],
    }, '-=1500');

    timeline.play();
  } 

  removeVideo() {
    const timeline = anime.timeline({  
      autoplay: false,
      easing: bezier,
      duration: 2200, 
    });

    timeline
    // .add({
    //   targets: document.querySelector('.banner'),
    //   translateY: ['-100%', '0'],
    // }, 0)
    .add({
      targets: this.playerContainer,
      translateX: ['0', '100%'],
    }, 0)
    .add({
      targets: document.querySelector('.wrap'),
      translateX: ['-100%', '0'],
    }, '-=1500')

    timeline.play();

    // Remove or create multiple ...
    // multiple = into function or condition -> Handler Violation and CORS block!
    document.querySelector('.video__player--container').remove();
  }
  
  setPlayerYouTube(player) {
    return new YouTubePlayer(player, {
      videoId: this.videoId,
      playerVars: this.youTubeOptions,
    });
  } 
}

export default VideoPlayer;