/* eslint-disable no-unused-vars */
/* global params */

import anime from 'animejs/lib/anime.es.js';
import dotsMenu from './dots-menu.js';

export default {
  
  init(html) {

    // const parser = new DOMParser();
    // if(html){
    //   const content = parser.parseFromString(html, 'text/html');
    
    //   this.initSidebar(content.querySelector('.sidebar--years').querySelector('ul'));
    // } else {
    //   this.initSidebar(document.querySelector('.sidebar--years').querySelector('ul'));
    // }
   
  },  

  finalize() {
    this.initDotBar();
  },

  destroy() {
    this.destroyTopbar();
   },  

  initTopbar(topbarContent) {
    const topbar = document.getElementById('topbar__container');
    document.body.classList.add('topbar-active');
  },

  destroyTopbar() {
    const topbar = document.getElementById('topbar__container');
    document.body.classList.remove('topbar-active');
  },

  initDotBar() {
    
    if(document.querySelectorAll('.dotmenu')){
      const dotmenu_dots = document.querySelectorAll('.dotmenu .dot');

      if(dotmenu_dots) {
        dotsMenu.removeDotCurrent(window.location.href , dotmenu_dots);
      }

    }

  },

};
