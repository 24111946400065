/* eslint-disable no-unused-vars */
/* global params */
/* global FWP */

import Carousel from '../util/Carousel';
import Navigation from '../util/Navigation';
import VideoPlayer from '../util/VideoPlayer';
import AudioPlayer from '../util/AudioPlayer';
import archiveFilter from './archive-filter';

export default {
  
  init() {

    const carousels = [];
    const videos = [];
    const audios = [];
    const navigation = new Navigation(document.querySelector('.main'));
    navigation.init();

    document.querySelectorAll('.carousel__container').forEach( element => {
      carousels.push(new Carousel(element));
    });

    document.querySelectorAll('.video').forEach( element => {
      videos.push(new VideoPlayer(element));
    });

    document.querySelectorAll('.btn-audio').forEach( element => {
      audios.push(new AudioPlayer(element));
    });

    if(document.querySelector('#filter_opener')) {
      
      document.getElementById('filter_opener').addEventListener('click', () => {
        archiveFilter.filterMenuOpen();
      });
  
      document.getElementById('filter_closer').addEventListener('click', () => {
        console.log('closer click!');
        archiveFilter.filterMenuClose();
      });
    }

  },

  finalize() {
    
  },

};
