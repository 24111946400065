// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import archive from './routes/years-archive';
import singleMemories from './routes/single-memories';
import archiveFilter from './routes/archive-filter';

/** Populate Router instance with DOM routes */
const routes = new Router({
  common,
  archive,
  singleMemories,
  archiveFilter,
});

// // Load Events
jQuery(document).ready(() => {
  routes.loadEvents();
});
