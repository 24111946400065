/* eslint-disable no-unused-vars */

import Swiper, { Autoplay, Pagination, Navigation, Lazy } from 'swiper';
import anime from 'animejs/lib/anime.es.js';
import bezier from '../util/Utils';

Swiper.use([Navigation, Lazy]);

class Carousel {

  constructor(target) {

    this.swiperElement = target.querySelector('.swiper-container');

    this.swiperPrev = target.querySelector('.swiper-button-prev');
    this.swiperNext = target.querySelector('.swiper-button-next');


    this.options = {
      init: false,
      slidesPerView: 1.13,
      spaceBetween: 0,
      slidesOffsetAfter: 0,
      height: 380,
      autoHeight: false,
      allowTouchMove: false,
      watchSlidesProgress: true,
      watchSlidesVisibility: true, 
      virtualTranslate: true,
    };

    this.swiper = null;

    this.init();
  }

  init() {
    this.swiperPrev.style.opacity = 0;
    this.swiperPrev.style.visibility = 'hidden';
    this.swiper = new Swiper(this.swiperElement, this.options);
  
    this.swiper.init();

    if (this.swiperElement.querySelectorAll('.swiper-slide').length == 1) {
      this.swiperNext.style.opacity = 0;
      this.swiperNext.style.visibility = 'hidden';
    }

    this.handleEvents();
  }


  onSlideShow( swiper ) {
    swiper.lazy.load();
  }


  handleEvents($videoTarget, swiper) {

    
    let targets, currentSlide;
   

    this.swiperPrev.addEventListener('click', () => {
      
      if(this.swiper.realIndex == 0 || anime.running.length > 0) {
        return;
      }
      
      currentSlide = this.swiperElement.querySelector('.swiper-slide-prev');
      targets = this.swiperElement.querySelectorAll('.swiper-slide:not(.fixed)');
      currentSlide.classList.remove('fixed');


      if(this.swiper.realIndex == 1) {
        anime({
          targets: this.swiperPrev,
          opacity: 0,
          complete: (anim) => {
            this.swiperPrev.style.visibility = 'hidden';
          },
          easing: 'cubicBezier(0.05, 0.95, 0.4, 0.95)',
        });
      }

      this.swiperNext.style.visibility = 'visible';

      if(this.swiper.realIndex == this.swiper.slides.length - 1) {
        anime({
          targets: this.swiperNext,
          opacity: 1,
          easing: 'cubicBezier(0.05, 0.95, 0.4, 0.95)',
        });
      }

      this.swiper.slidePrev();
      
      anime({
        targets: targets,
        translateX: '+=100%',
        delay: anime.stagger(100),
        easing: 'cubicBezier(0.05, 0.95, 0.4, 0.95)',
      });

      anime({
        targets: currentSlide,
        scale: 1,
        easing: 'cubicBezier(0.05, 0.95, 0.4, 0.95)',
      });
    });

    this.swiperNext.addEventListener('click', () => {
      
      if(this.swiper.realIndex + 1 == this.swiper.slides.length || anime.running.length > 0) {
        return;
      }
      
      currentSlide = this.swiperElement.querySelector('.swiper-slide-active');
      currentSlide.classList.add('fixed');
      targets = this.swiperElement.querySelectorAll('.swiper-slide:not(.fixed)');
     
      this.swiper.slideNext();

      console.log(this.swiper.realIndex);
      
      if(this.swiper.realIndex > 0) {

        this.swiperPrev.style.visibility = 'visible';

        anime({
          targets: this.swiperPrev,
          opacity: 1,
          easing: 'cubicBezier(0.05, 0.95, 0.4, 0.95)',
        });
      } 

      if(this.swiper.realIndex == this.swiper.slides.length - 1) {
        anime({
          targets: this.swiperNext,
          opacity: 0,
          complete: (anim) => {
            this.swiperNext.style.visibility = 'hidden';
          },
          easing: 'cubicBezier(0.05, 0.95, 0.4, 0.95)',
        });
      } 

      anime({
        targets: currentSlide,
        scale: 0.7,
        easing: 'cubicBezier(0.05, 0.95, 0.4, 0.95)',
      });

      anime({
        targets: targets,
        translateX: '-=100%',
        delay: anime.stagger(100),
        easing: 'cubicBezier(0.05, 0.95, 0.4, 0.95)',
      });
    });
  }
}

export default Carousel;
