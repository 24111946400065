/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* global params */

class AudioPlayer {

  constructor(target) {
    
    this.target = target;
    this.audioId = '';
    this.playerContainer = null;

    this.init();
  }

  init() {
    
    this.audioId = this.target.dataset.audioId;
    this.handleEvents();

  }

  handleEvents() {

    this.target.addEventListener('click', () => {
      if (this.target.classList.contains('active') ) {
        this.AudioMuted();
      }
      else {
        this.AudioPlay();
      }
    });

  }

  AudioPlay() {
    this.target.classList.remove('muted');
    this.target.classList.add('active');

    this.playerContainer = document.createElement('audio');
    this.playerContainer.id = 'audio-player';
    this.playerContainer.classList.add('audio__player--container');
    this.playerContainer.controls = 'controls';
    this.playerContainer.type = 'audio/mpeg';
    this.playerContainer.src = this.audioId;
    this.playerContainer.src = 'https://interactive-examples.mdn.mozilla.net/media/cc0-audio/t-rex-roar.mp3';

    document.body.appendChild(this.playerContainer);
   
    this.playerContainer.play();

    this.AudioEnd();
  }

  AudioMuted() {
    this.target.classList.remove('active');
    this.target.classList.add('muted');

    this.playerContainer.pause();

    document.querySelector('.audio__player--container').remove();
  }

  AudioEnd() {
    this.playerContainer.addEventListener('ended', () => {
      this.target.classList.remove('active');
    });
  }

}

export default AudioPlayer;