export default {
  init() {

  },

  finalize() {

  },

  removeDotCurrent(url , dots) {

    dots.forEach(dot => {

      if (url === dot.href) {
        if(!dot.classList.contains('current')) {
          this.addDotCurrent(dot.id);
        }
      }
      else {
        dot.classList.remove('current');
      }

    });

  },

  addDotCurrent(dot){
    document.getElementById(dot).classList.add('current');
  },

};
