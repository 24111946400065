/* eslint-disable no-unused-vars */
/* global params */
/* global FWP */

export default {
  init() {

    if(document.querySelector('#filter_opener')) {
      document.getElementById('filter_opener').addEventListener('click', () => {
        this.filterMenuOpen();
      });
  
      document.getElementById('filter_closer').addEventListener('click', () => {
        this.filterMenuClose();
      });
    }
  },

  finalize() {
    
  },

  filterMenuOpen() {
    document.querySelector('#hamburger__container').classList.add('d-none');
    document.querySelector('.sidebar__container').classList.add('open');
    document.body.classList.add('overflow-hidden');
    document.querySelector('html').classList.add('overflow-hidden');
  },

  filterMenuClose() {
    document.querySelector('#hamburger__container').classList.remove('d-none');
    document.querySelector('.sidebar__container').classList.remove('open');
    document.body.classList.remove('overflow-hidden');
    document.querySelector('html').classList.remove('overflow-hidden');
  },

};
