/* eslint-disable no-unused-vars */
/* global params */

import LocomotiveScroll from 'locomotive-scroll';

class Scroll {
 
  constructor(target) {
      this.target = target;

      this.smooth = null;
      
      this.smoothConfig = {
        el: this.target,  
        smooth: true,
        smoothMobile: false,
        repeat: true,
        lerp: 0.4, 
        multiplier: 0.5,
      };
      
      this.toConfig = {
        offset: 0,
        duration: 2000,
        easing: [.3,.96,0,1.03],
      }
  }

  init() {

    this.smooth = new LocomotiveScroll(this.smoothConfig);
  }

  update() {
    this.smooth.update();
  }

  start() {
    this.smooth.start();
  }  

  stop() {
    this.smooth.stop();
  }  

  destroy() {
    this.smooth.destroy();
  }

  getPosition() {
    return this.smooth.scroll.instance.scroll.y;
  }

  async scrollTo(target, duration = false) {

    if(duration) {
      this.toConfig.duration = duration;
    }

    this.toConfig.callback = () => { 
      document.dispatchEvent(new CustomEvent('scrolled', {
        detail: {
          target: target,
        },
      }));
    };


    this.smooth.scrollTo(target, this.toConfig);
 
  }

}

export default Scroll;